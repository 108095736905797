import React from 'react';
import { connect } from 'react-redux'
//import i18next from "i18next"
//import { Link } from 'react-router-dom'
import { Button, Input } from 'reactstrap'
import { MdQrCodeScanner } from 'react-icons/md'
import { IoIosArrowUp } from 'react-icons/io'

//import moment from 'moment';
import { Container, Row, Col } from 'reactstrap';

import { fobiCore } from './FobiCore'
import { addVeranstaltungPanelSetVNR, addVeranstaltungPanelSetVNRPwd, fetchVeranstaltung, fetchVeranstaltungWithJWT } from './store/veranstaltungen/actions'
import { showConfirmDialog } from './store/confirmDialog/actions';
import AddVeranstaltungAlert from './components/AddVeranstaltungAlert';
import { scanAddVeranstaltungBarcode } from './scanbarcode/cordovascan';
import { mobileAppModus } from './EivngCore';
import VnrCredentialsScannerDialog from './components/VnrCredentialsScannerDialog';
import { getVeranstaltungsJWT } from './store/veranstaltungen/handler';

class AddVeranstaltungPanel extends React.Component {
	
constructor(props){
	super(props);
	this.state = {
		readyToFetch: false,
		vnr: "2760602019241910008",
		vnrpwd: "unknown",
		showScanDialog: false
	};
	this.initLocalVars();
}
initLocalVars(){
	this.flagFetchNewVNREnabled = false;
}
setShowScanDialog(showScanDialog){
	this.setState({showScanDialog});
}
render(){
	const {fetchCounter, vnr, vnrpwd, changeVNR, changeVNRPwd, showConfirmDialog, loadVeranstaltung} = this.props;
	const vnrClassNameAddOn = (vnr.length ===0 || fobiCore.isVNR(vnr))?"":" is-invalid";
	const fetchButtonEnabled = fetchCounter === 0 && fobiCore.isVNR(vnr) && vnrpwd.length > 0;
	const fetchButtonColor = fetchButtonEnabled?"secondary":"secondary";

	//const fetchButtonColor = fetchButtonEnabled?"secondary":"light";
	return (
		<Container className="mt-2 mb-2">
			<h4>Veranstaltung hinzufügen<IoIosArrowUp fontSize="1.5em" color="orange"
			className="clickableValue" onClick={(e) => this.props.toggleAddVNRPanel()} ></IoIosArrowUp></h4>
			<Row className="form-group">
				<Col lg className="mt-2 mb-2">
					<Row >
						<label className="col-sm-5 col-form-label text-sm-right" htmlFor="vnr">VNR</label>
        		<div className="col-sm-7">
							<Input id="vnr" placeholder='VNR oder Einrichtungstoken'
								className={"form-control" + vnrClassNameAddOn} required={true} valid={fobiCore.isVNR(vnr)} type="text" autoFocus = {!mobileAppModus && !vnr}
            		onChange={(e) => changeVNR(e.target.value)} value={vnr} />
        		</div>
					</Row>
				</Col>
				<Col lg className="mt-2 mb-2">
					<Row >
						<label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>Passwort</label>
        		<div className="col-sm-7">
							<Input id="vnrpwd" className="form-control" required={true} type="password" placeholder='z.B. geheim'
            		onChange={(e) => changeVNRPwd(e.target.value)} value={vnrpwd} autoFocus = {!mobileAppModus && vnr}/>
        		</div>
					</Row>
				</Col>
			</Row>
			<AddVeranstaltungAlert></AddVeranstaltungAlert>
			<Row className="d-flex">
			{/* <div className="col text-right">
				<Button outline color="secondary" onClick={(e) => this.props.toggleAddVNRPanel()}>Schließen</Button>
			</div> */}
			<div className="col text-right">
			
			{ mobileAppModus &&
				<Button title="Scan barcode der Veranstaltung" className="d-inline"  color="secondary" outline 
				onClick={ (e) => {
					scanAddVeranstaltungBarcode(changeVNR, showConfirmDialog)
				}}><MdQrCodeScanner size="1em" color="orange" ></MdQrCodeScanner>
				</Button>
			}
			{ !mobileAppModus &&
			<span className='p-2'>
				<Button title="Zugangsdaten einscannen" className="d-inline my-2"  color="secondary" outline
					onClick={ (e) => { this.setShowScanDialog(true);  }}>
					<MdQrCodeScanner size="1em" color="orange" ></MdQrCodeScanner>
					<span className="d-sm-none ml-2">Zugangsdaten aus Barcode</span>
				</Button>
				<VnrCredentialsScannerDialog isOpen={this.state.showScanDialog} toggle={() => this.setShowScanDialog(false)} 
					 />
			</span>
			}
			<Button  disabled={!fetchButtonEnabled} color={ fetchButtonColor } className="ml-4"
				onClick={ (e) => loadVeranstaltung(vnr, vnrpwd) } >Veranstaltung hinzufügen
			</Button>
			</div>	
			</Row>
			{this.props.children}
	</Container>
	);
	}
}

const mapStateToProps = function(state) {
  return { 
		vnr: state.addVeranstaltungPanel.vnr,
		vnrpwd: state.addVeranstaltungPanel.vnrpwd,
		fetchCounter: state.fetchCounter
	}
}

 const mapDispatchToProps = dispatch => ({
 	changeVNR: vnr => {
		const jwt = getVeranstaltungsJWT(vnr);
		if(jwt){
			dispatch(fetchVeranstaltungWithJWT(jwt));
		}
		dispatch(addVeranstaltungPanelSetVNR(vnr));
		
	},
	changeVNRPwd: vnrpwd => {
		const jwt = getVeranstaltungsJWT(vnrpwd);
		if(jwt){
			dispatch(fetchVeranstaltungWithJWT(jwt));
		}
		dispatch(addVeranstaltungPanelSetVNRPwd(vnrpwd));
	},
	showConfirmDialog: (title, message, onConfirm) => dispatch(showConfirmDialog(title, message, onConfirm)),
	loadVeranstaltung: (vnr, vnrpwd) => dispatch(fetchVeranstaltung(vnr, vnrpwd))
 })

export default connect(mapStateToProps, mapDispatchToProps)(AddVeranstaltungPanel) 