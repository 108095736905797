// localStorage.js
export const loadState = () => {
  try {
		const serializedState = localStorage.getItem('state');
		if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const cleanState = JSON.parse(JSON.stringify(state));
    if( cleanState.punkteMeldung.teilnahmeMeldungen) delete cleanState.punkteMeldung.teilnahmeMeldungen;
    const serializedState = JSON.stringify(cleanState);
    localStorage.setItem('state', serializedState);
  } catch(err) {
    console.error(err);
  }
};